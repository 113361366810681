<!-- Created by henian.xu on 2023/11/20. -->

<template>
    <Page>
        <Container
            class="lottery-turntable-page"
            ref="container"
        >
            <TurntableTitle />
            <div class="rule-wrap">
                <div class="inner">
                    <!--                    <img src="./images/rule-content.png">-->
                    <img :src="raffle.raffleDescUrl">
                </div>
            </div>
            <div class="lottery-badge">
                <img src="./images/badge.png">
            </div>
        </Container>
    </Page>
</template>

<script>
import TurntableTitle from './_turntableTitle.vue';

export default {
    name: 'LotteryTurntablePage',
    components: { TurntableTitle },
    data() {
        return {
            raffleCode: this.$route.params.id || '',
            //     raffleDescUrl
            detail: {},
        };
    },
    computed: {
        raffle() {
            return this.detail.raffle || {};
        },
    },
    methods: {
        async getDetail() {
            const { raffleCode } = this;
            const {
                data: { data },
            } = await this.$api.Buyer.Pm.Raffle.raffleDetail({
                raffleCode,
            });
            this.detail = data;
        },
    },
    activated() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
.lottery-turntable-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background: transparent url('./images/bg.jpg') top center / cover no-repeat;
    color: #fff;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    > .rule-btn {
        display: block;
        position: fixed;
        z-index: $z-index-1 + 10;
        right: $padding-big;
        top: $padding-big;
        width: 1.33rem;
    }

    > .turntable {
        margin-top: -0.8rem;
    }

    > .rule-wrap {
        flex: 1 1 auto;
        margin-top: -0.5rem;
        > .inner {
            position: relative;
            width: 5.44rem;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
            background-color: rgba(#6b3219, 0.78);
            border: solid #e49875;
            border-width: 2px 2px 0 2px;
            border-top-left-radius: $padding-small;
            border-top-right-radius: $padding-small;
            padding: $padding-big;
        }
    }

    > .water {
        position: fixed;
        z-index: $z-index-1 + 10;

        &.left {
            left: 0;
            bottom: 0;
            width: 3.39rem;
        }

        &.right {
            right: 0;
            bottom: 2.55rem;
            width: 1.55rem;
        }
    }

    > .lottery-badge {
        position: fixed;
        z-index: $z-index-1 + 10;
        right: $padding-big;
        bottom: $padding-big;
        width: 1.92rem;
    }
}
</style>
