<!-- Created by henian.xu on 2024/4/11. -->

<template>
    <Page>
        <Container class="lottery-turntable2-page">
            <div class="content">
                <Linker
                    class="rule-btn"
                    :to="`/lottery/turntable2/rule/${raffleCode}`"
                >
                    <img :src="ruleBtnUrl">
                </Linker>
                <Linker
                    class="gift-list-btn"
                    :to="`/lottery/giftList/${raffleCode}`"
                >
                    <img :src="giftListBtnUrl">
                </Linker>
                <div class="bg">
                    <img
                        :src="pageBgUrl"
                        alt="bg"
                    >
                </div>
                <div
                    class="inner"
                    :style="`background-image: url(${pageBgUrl});`"
                >
                    <div
                        v-if="!raffleDetail.isNull"
                        class="result-warp"
                    >
                        <div class="inner">
                            <div
                                class="gift"
                                @click="()=>{
                                    onGift()
                                }"
                            >
                                <img :src="raffleDrawResultImageUrl">
                            </div>
                        </div>
                    </div>
                    <Turntable
                        v-else
                        :dataset="rafflePriceList"
                        :raffle-template-image-list="raffleTemplateImageList"
                        :prop-key="{src:'rafflePrizeImageUrl'}"
                        @start="(e)=>{onTurntableStart(e)}"
                        @end="(e)=>{onTurntableEnd(e)}"
                    />
                    <div
                        v-if="raffleDetail.isNull"
                        class="footer-bg"
                    >
                        <img
                            :src="pageFooterUrl"
                            alt="footer-bg"
                        >
                    </div>
                </div>
            </div>
        </Container>
        <XDialog
            ref="saveDeliveryDialog"
            label="中奖人信息"
        >
            <div class="save-delivery-dialog">
                <template v-if="raffleDetailStatus === 20">
                    <XForm

                        ref="form"
                        :rules="saveDeliveryDialog.formRules"
                        :model="saveDeliveryDialog.formModel"
                    >
                        <FormItem
                            prop="fullName"
                            label="中奖者姓名："
                        >
                            <x-input
                                type="text"
                                v-model="saveDeliveryDialog.formModel.fullName"
                                placeholder="请输入收货人"
                            />
                        </FormItem>
                        <FormItem
                            prop="mobile"
                            label="中奖者电话："
                        >
                            <x-input
                                type="text"
                                v-model="saveDeliveryDialog.formModel.mobile"
                                placeholder="请输入手机"
                            />
                        </FormItem>
                        <FormItem
                            v-if="isNeedIdCard"
                            class="column"
                            prop="idCardNo"
                            label="中奖者身份证号："
                        >
                            <x-input
                                class="w-100per"
                                type="text"
                                v-model="saveDeliveryDialog.formModel.idCardNo"
                                placeholder="请输入收货人"
                            />
                        </FormItem>
                        <FormItem
                            class="column"
                            prop="address"
                            label="中奖者地址："
                        >
                            <x-input
                                class="w-100per"
                                type="textarea"
                                :auto-size="{minRows:1,maxRows:2}"
                                v-model="saveDeliveryDialog.formModel.address"
                                placeholder="请输入中奖者地址"
                            />
                        </FormItem>
                    </XForm>
                    <div class="tips">
                        本次抽奖活动因发放奖品会采集参加活动客户的身份信息，本公司承诺所采集的信息仅用于抽奖活动、不用作其他用途。
                    </div>
                    <div class="ctrl">
                        <Button
                            radius
                            theme="main"
                            label="提交"
                            @click="()=>{onSubmit()}"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="save-info">
                        <div class="item">
                            <div class="label">
                                中奖者姓名:
                            </div>
                            <div class="value">
                                {{ raffleDetail.fullName }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">
                                中奖者电话:
                            </div>
                            <div class="value">
                                {{ raffleDetail.mobile }}
                            </div>
                        </div>
                        <div
                            v-if="isNeedIdCard"
                            class="item"
                        >
                            <div class="label">
                                中奖者身份证号:
                            </div>
                            <div class="value">
                                {{ raffleDetail.idCardNo }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">
                                中奖者地址:
                            </div>
                            <div class="value">
                                {{ raffleDetail.address }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">
                                奖品状态:
                            </div>
                            <div class="value tc-red">
                                {{ raffleDetail.raffleDetailStatusName }}
                            </div>
                        </div>
                    </div>
                    <div class="ctrl">
                        <Button
                            radius
                            theme="main"
                            label="确定"
                            @click="()=>{$refs.saveDeliveryDialog.close()}"
                        />
                    </div>
                </template>
            </div>
        </XDialog>
        <XDialog
            ref="followQrcodeDialog"
            :mask-click="false"
        >
            <div class="follow-qrcode">
                <div class="header">
                    长按关注公众号
                </div>
                <div class="body">
                    <XImage
                        :src="wxaccountQrCodeImageUrl"
                        :aspect-ratio="1"
                    />
                </div>
                <div class="fs-little ta-c tc-g6 ma-t">
                    仅关注公众号后方可参加抽奖活动
                </div>
                <div class="footer">
                    <div
                        class="btn radius btn-g8 tc-red"
                        @click="onConcern"
                    >
                        关注公众号后返回点击此处抽奖
                    </div>
                    <!--<div class="btn btn-main" @click="onClose">关　　闭</div>-->
                </div>
            </div>
        </XDialog>
    </Page>
</template>

<script>
import Turntable from './_turntable.vue';
import { turntableMixin } from 'pages/lottery/turntable2/turntableStore';

export default {
    name: 'LotteryTurntablePage',
    mixins: [turntableMixin],
    components: { Turntable },
    data() {
        return {
            raffleCode: this.$route.params.id || '',
            detail: {},
            saveDeliveryDialog: {
                formModel: {
                    // raffleCode: '',
                    fullName: '',
                    mobile: '',
                    address: '',
                    idCardNo: '',
                },
                formRules: {
                    fullName: {
                        required: true,
                        message: '中奖者姓名不能为空',
                    },
                    mobile: [
                        {
                            required: true,
                            message: '中奖者电话不能为空',
                        },
                        {
                            validator: this.$utils.Valid.mobile,
                            message: '请输入正确的手机号码',
                        },
                    ],
                    address: {
                        required: true,
                        message: '中奖者地址不能为空',
                    },
                    idCardNo: {
                        required: true,
                        message: '中奖者身份证号不能为空',
                    },
                },
            },
            isMounted: false,
            isVirtualPrizeClicked: false,
        };
    },
    computed: {
        rafflePriceList() {
            return this.detail.rafflePriceList || [];
        },
        raffle() {
            return this.detail.raffle || {};
        },
        raffleDetail() {
            return this.detail.raffleDetail || { isNull: true };
        },
        isSubscribe() {
            if (!this.isMounted) return null;
            const isSubscribe = this.detail.isSubscribe;
            return isSubscribe !== undefined ? isSubscribe : null;
        },
        wxaccountQrCodeImageUrl() {
            return this.$globalVar.wxaccountQrCodeImageUrl || '';
        },
        rafflePrizeType() {
            return this.raffleDetail.rafflePrizeType || 0;
        },
        raffleDetailStatus() {
            return this.raffleDetail.raffleDetailStatus || 0;
        },
        isVirtualPrize() {
            return [1, 2].includes(this.rafflePrizeType);
        },
        isInKindPrize() {
            return this.rafflePrizeType === 3;
        },
        isNoopPrize() {
            return this.rafflePrizeType === 99;
        },
        isNeedIdCard() {
            return !!this.raffleDetail.isNeedIdCard;
        },
        hasSaveDelivery() {
            const { isInKindPrize, raffleDetailStatus } = this;
            return isInKindPrize && raffleDetailStatus === 20;
        },
        raffleDrawResultImageUrl() {
            const { raffleDetail, raffleDetailStatus, isInKindPrize, isVirtualPrizeClicked } = this;
            const { raffleDrawImageUrl, raffleNoticeImageUrl } = raffleDetail;
            if (isInKindPrize) {
                return raffleDetailStatus === 20 ? raffleDrawImageUrl : raffleNoticeImageUrl;
            }
            return isVirtualPrizeClicked ? raffleNoticeImageUrl : raffleDrawImageUrl;
        },
        raffleTemplateImageList() {
            return this.raffle.raffleTemplateImageList || [];
        },
        pageBgUrl() {
            return this.raffleTemplateImageList[0] || '';
        },

        pageFooterUrl() {
            return this.raffleTemplateImageList[5] || '';
        },
        ruleBtnUrl() {
            return this.raffleTemplateImageList[6] || '';
        },
        giftListBtnUrl() {
            return this.raffleTemplateImageList[7] || '';
        },
    },
    watch: {
        isSubscribe: {
            handler(val) {
                if (val !== false || this.$globalVar.isDev) return;
                if (this.$refs.followQrcodeDialog) {
                    this.$refs.followQrcodeDialog.open();
                }
            },
            immediate: true,
        },
    },
    methods: {
        async getDetail() {
            const { raffleCode } = this;
            const {
                data: { data },
            } = await this.$api.Buyer.Pm.Raffle.raffleDetail({
                raffleCode,
            });
            if (data.raffleDetail) {
                this.$messageBox.alert('该抽奖码已被使用');
            }
            this.detail = data;
        },
        async onTurntableStart({ resolve, reject } = {}) {
            const { raffleCode } = this;
            try {
                const {
                    data: { data },
                } = await this.$api.Buyer.Pm.Raffle.raffleDraw({
                    raffleCode,
                });
                resolve(data);
            } catch (e) {
                reject(e);
            }
        },
        onTurntableEnd(data) {
            this.$set(this.detail, 'raffleDetail', data);
        },
        onGift() {
            const {
                isNoopPrize,
                isVirtualPrize,
                isInKindPrize,
                isVirtualPrizeClicked,
                $refs: { saveDeliveryDialog },
            } = this;
            if (isNoopPrize) return;
            if (isVirtualPrize && !isVirtualPrizeClicked) {
                this.isVirtualPrizeClicked = true;
                this.$messageBox.alert('已成功领取!');
                return;
            }
            if (isInKindPrize && saveDeliveryDialog) {
                saveDeliveryDialog.open();
            }
        },
        async onSubmit() {
            await this.$refs['form'].validate();
            const { saveDeliveryDialog, raffleCode } = this;
            const params = {
                raffleCode,
                ...saveDeliveryDialog.formModel,
            };
            const {
                data: { msg, data },
            } = await this.$api.Buyer.Pm.Raffle.saveDeliveryInfo(params);
            this.$refs.saveDeliveryDialog.close();
            if (msg) this.$messageBox.tips(msg);
            this.$set(this.detail, 'raffleDetail', data);
        },
        onConcern() {
            window.location.reload(true);
        },
    },
    activated() {
        this.getDetail();
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
.lottery-turntable2-page {
    /*display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background: transparent url('./images/bg.jpg') top center / 100% auto no-repeat;*/

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    > .content {
        position: relative;
        color: #fff;

        > .rule-btn {
            display: block;
            position: absolute;
            z-index: $z-index-1 + 10;
            right: 0.26rem;
            top: 0.4rem;
            width: 1.33rem;
        }

        > .gift-list-btn {
            display: block;
            position: absolute;
            z-index: $z-index-1 + 10;
            right: 0.26rem;
            top: 0.9rem;
            width: 1.33rem;
        }

        > .bg {
            height: 100vh;
            overflow: hidden;
        }
        > .inner {
            position: absolute;
            top: 0;
            right: 0;
            //bottom: 0;
            left: 0;
            min-height: 100vh;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            background: transparent url('./images/bg.jpg') top center / 100% auto no-repeat;
            //background-image: url('./images/bg.jpg');

            > .turntable {
                margin-top: (540 / 750 * 100%);
            }
            > .result-warp {
                z-index: 100;
            }

            > .result-warp {
                //position: absolute;
                //left: 0;
                //right: 0;
                //bottom: 0;
            }
            > .footer-bg {
                margin-top: -150px;
                /*position: absolute;
                left: 0;
                right: 0;
                top: (1046px);*/
            }
        }
    }
}
.save-delivery-dialog {
    > .ctrl {
        text-align: center;
    }
    > .tips {
        font-size: 85%;
        color: $color-text-minor;
        margin-bottom: $margin;
    }

    > .save-info {
        > .item {
            line-height: 1.2;
            padding: $padding 0;
            > .label {
                font-size: 85%;
                color: $color-text-minor;
            }
            > .value {
            }

            + .item {
                border-top: 1px solid $color-border;
            }
        }
    }
}
.follow-qrcode {
    > .header {
        margin-bottom: $margin;
        text-align: center;
        background-color: $color-red;
        color: #fff;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 0.5em;
        border-radius: 0.1rem;
        padding: 0.1rem 0;
    }
    > .footer {
        text-align: center;
        margin-top: $margin;
    }
}
</style>
