<!-- Created by henian.xu on 2023/11/22. -->

<template>
    <div class="turntable-title">
        <div class="badge-1194">
            <img src="./images/1194.png">
        </div>
        <div class="title">
            <img src="./images/title.png">
        </div>
    </div>
</template>

<script>
export default {
    name: 'TurntableTitle',
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.turntable-title {
    padding-top: 0.5rem;
    position: relative;
    z-index: $z-index-1 + 1;
    > .badge-1194 {
        position: absolute;
        top: $padding-big;
        left: $padding-big;
        width: 1.6rem;
    }
    > .title {
        width: 7.36rem;
        margin: 0 auto;
    }
}
</style>
