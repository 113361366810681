<!-- Created by henian.xu on 2023/11/20. -->

<template>
    <Page>
        <Container
            class="lottery-turntable2-page"
            ref="container"
        >
            <div class="rule-wrap">
                <div class="inner">
                    <!--                    <img src="./images/rule-content.png">-->
                    <img :src="raffle.raffleDescUrl">
                </div>
            </div>
        </Container>
    </Page>
</template>

<script>
export default {
    name: 'LotteryTurntablePage',
    data() {
        return {
            raffleCode: this.$route.params.id || '',
            //     raffleDescUrl
            detail: {},
        };
    },
    computed: {
        raffle() {
            return this.detail.raffle || {};
        },
    },
    methods: {
        async getDetail() {
            const { raffleCode } = this;
            const {
                data: { data },
            } = await this.$api.Buyer.Pm.Raffle.raffleDetail({
                raffleCode,
            });
            this.detail = data;
        },
    },
    activated() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
.lottery-turntable2-page {
    img {
        display: block;
        width: 100%;
        height: auto;
    }
}
</style>
