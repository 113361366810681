<!-- Created by henian.xu on 2024/4/11. -->

<template>
    <Page>
        <Container>
            <div
                class="pa-a ta-c fs-more tc-g5"
                style="margin-top: 100px;"
            >
                {{ tipsMsg }}
            </div>
        </Container>
    </Page>
</template>

<script>
import { Buyer } from '@/api';

export default {
    name: 'DistributePage',
    data() {
        return {
            tipsMsg: '加载中...',
        };
    },
    // 路由守卫
    beforeRouteEnter(to, from, next) {
        // 在渲染该组件的对应路由被 confirm 前调用
        // 不！能！获取组件实例 `this`
        // 因为当守卫执行前，组件实例还没被创建
        // next(vm => {});
        const {
            params: { id: raffleCode },
        } = to;
        Buyer.Pm.Raffle.raffleDetail({ raffleCode, _isHandleError: true }).then(({ data: { data, msg, success } }) => {
            console.log(data, msg, success);
            if (success && data) {
                let nextUrl = `/lottery/turntable/index/${raffleCode}`;
                if (data.raffle && data.raffle.raffleTemplateImageList && data.raffle.raffleTemplateImageList.length) {
                    nextUrl = `/lottery/turntable2/index/${raffleCode}`;
                }
                next(nextUrl);
            } else {
                next(vm => {
                    vm.tipsMsg = msg;
                });
            }
        });
        console.log(raffleCode);
        // next();
    },
};
</script>

<style lang="scss">
</style>
