<!-- Created by henian.xu on 2023/11/29. -->

<template>
    <div class="gift-item">
        <div
            class="label-wrap"
            slot="label"
        >
            <div class="label">
                {{ dataset.rafflePrizeName }}
            </div>
            <div class="express">
                <div
                    v-if="dataset.expressName"
                    class="item"
                >
                    物流公司: <span>{{ dataset.expressName }}</span>
                </div>
                <div
                    v-if="dataset.trackingNumber"
                    class="item"
                >
                    物流单号: <span>{{ dataset.trackingNumber }}</span>
                </div>
                <div
                    v-if="dataset.shippingTime"
                    class="item"
                >
                    发货时间: <span>{{ dataset.shippingTime }}</span>
                </div>
            </div>
            <div class="time">
                {{ dataset.raffleTime|moment('YYYY-MM-DD HH:mm:ss') }}
            </div>
        </div>
        <div

            class="extra"
            slot="extra"
        >
            <Button
                v-if="isInKindPrize"
                theme="main"
                radius
                @click="onGift"
            >
                {{ raffleDetailStatus === 20?'领取':'查看' }}
            </Button>
            <Button
                v-if="isVirtualPrize"
                theme="main"
                radius
                @click="()=>{onLook()}"
            >
                查看
            </Button>
        </div>
        <XDialog
            ref="saveDeliveryDialog"
            label="中奖人信息"
        >
            <div class="save-delivery-dialog">
                <template v-if="raffleDetailStatus === 20">
                    <XForm

                        ref="form"
                        :rules="saveDeliveryDialog.formRules"
                        :model="saveDeliveryDialog.formModel"
                    >
                        <FormItem
                            prop="fullName"
                            label="中奖者姓名："
                        >
                            <x-input
                                type="text"
                                v-model="saveDeliveryDialog.formModel.fullName"
                                placeholder="请输入收货人"
                            />
                        </FormItem>
                        <FormItem
                            prop="mobile"
                            label="中奖者电话："
                        >
                            <x-input
                                type="text"
                                v-model="saveDeliveryDialog.formModel.mobile"
                                placeholder="请输入手机"
                            />
                        </FormItem>
                        <FormItem
                            v-if="isNeedIdCard"
                            class="column"
                            prop="idCardNo"
                            label="中奖者身份证号："
                        >
                            <x-input
                                class="w-100per"
                                type="text"
                                v-model="saveDeliveryDialog.formModel.idCardNo"
                                placeholder="请输入收货人"
                            />
                        </FormItem>
                        <FormItem
                            class="column"
                            prop="address"
                            label="中奖者地址："
                        >
                            <x-input
                                class="w-100per"
                                type="textarea"
                                :auto-size="{minRows:1,maxRows:2}"
                                v-model="saveDeliveryDialog.formModel.address"
                                placeholder="请输入中奖者地址"
                            />
                        </FormItem>
                    </XForm>
                    <div class="tips">
                        本次抽奖活动因发放奖品会采集参加活动客户的身份信息，本公司承诺所采集的信息仅用于抽奖活动、不用作其他用途。
                    </div>
                    <div class="ctrl">
                        <Button
                            radius
                            theme="main"
                            label="提交"
                            @click="()=>{onSubmit()}"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="save-info">
                        <div class="item">
                            <div class="label">
                                中奖者姓名:
                            </div>
                            <div class="value">
                                {{ dataset.fullName }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">
                                中奖者电话:
                            </div>
                            <div class="value">
                                {{ dataset.mobile }}
                            </div>
                        </div>
                        <div
                            v-if="isNeedIdCard"
                            class="item"
                        >
                            <div class="label">
                                中奖者身份证号:
                            </div>
                            <div class="value">
                                {{ dataset.idCardNo }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">
                                中奖者地址:
                            </div>
                            <div class="value">
                                {{ dataset.address }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">
                                奖品状态:
                            </div>
                            <div class="value tc-red">
                                {{ dataset.raffleDetailStatusName }}
                            </div>
                        </div>
                    </div>
                    <div class="ctrl">
                        <Button
                            radius
                            theme="main"
                            label="确定"
                            @click="()=>{$refs.saveDeliveryDialog.close()}"
                        />
                    </div>
                </template>
            </div>
        </XDialog>
    </div>
</template>

<script>
export default {
    name: 'GiftItem',
    data() {
        return {
            saveDeliveryDialog: {
                formModel: {
                    // raffleCode: '',
                    fullName: '',
                    mobile: '',
                    address: '',
                    idCardNo: '',
                },
                formRules: {
                    fullName: {
                        required: true,
                        message: '中奖者姓名不能为空',
                    },
                    mobile: [
                        {
                            required: true,
                            message: '中奖者电话不能为空',
                        },
                        {
                            validator: this.$utils.Valid.mobile,
                            message: '请输入正确的手机号码',
                        },
                    ],
                    address: {
                        required: true,
                        message: '中奖者地址不能为空',
                    },
                    idCardNo: {
                        required: true,
                        message: '中奖者身份证号不能为空',
                    },
                },
            },
        };
    },
    props: {
        dataset: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        rafflePrizeType() {
            return this.dataset.rafflePrizeType || 0;
        },
        raffleDetailStatus() {
            return this.dataset.raffleDetailStatus || 0;
        },
        isNeedIdCard() {
            return !!this.dataset.isNeedIdCard;
        },
        isVirtualPrize() {
            return [1, 2].includes(this.rafflePrizeType);
        },
        isInKindPrize() {
            return this.rafflePrizeType === 3;
        },
        isNoopPrize() {
            return this.rafflePrizeType === 99;
        },
        hasSaveDelivery() {
            const { isInKindPrize, raffleDetailStatus } = this;
            return isInKindPrize && raffleDetailStatus === 20;
        },
    },
    methods: {
        onGift() {
            const {
                isNoopPrize,
                isVirtualPrize,
                isInKindPrize,
                $refs: { saveDeliveryDialog },
            } = this;
            if (isNoopPrize) return;
            if (isVirtualPrize) {
                this.$messageBox.alert('已成功领取!');
                return;
            }
            if (isInKindPrize && saveDeliveryDialog) {
                saveDeliveryDialog.open();
            }
        },
        onLook() {
            const { rafflePrizeType } = this;
            if (rafflePrizeType === 1) {
                // this.$router.push('/buyer/couponList/1');
                this.$messageBox.alert(
                    '中奖红包将由“北京方庄二锅头”公众号红包形式发放，请根据公众号消息点击领取，成功领取后将直接提现至微信钱包。',
                    '温馨提示',
                );
            } else {
                this.$router.push('/buyer/couponList/1');
            }
        },
        async onSubmit() {
            /* this.$emit('change', {
                ...this.dataset,
                raffleDetailStatus: 100,
            });*/
            await this.$refs['form'].validate();
            const { saveDeliveryDialog } = this;
            const params = {
                // raffleCode: this.$route.params.id || '',
                raffleCode: this.dataset.raffleCode || '',
                ...saveDeliveryDialog.formModel,
            };
            const {
                data: { msg, data },
            } = await this.$api.Buyer.Pm.Raffle.saveDeliveryInfo(params);
            this.$refs.saveDeliveryDialog.close();
            if (msg) this.$messageBox.tips(msg);
            this.$emit('change', data);
        },
    },
};
</script>

<style lang="scss">
.gift-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: $padding;
    background-color: #fff;

    > .label-wrap {
        flex: 1 1 1%;
        > .label {
            line-height: 1.2;
        }
        > .express {
            line-height: 1.2;
            color: $color-text-minor;
            margin-top: $margin-small;
            > .item {
                > span {
                    color: $color-text;
                }
            }
        }
        > .time {
            font-size: 85%;
            color: $color-text-minor;
            margin-top: $margin-small;
        }
    }
    > .extra {
        flex: 0 0 auto;
        margin-left: $margin-small;
    }
    + .gift-item {
        border-top: 1px solid $color-border;
    }
}

.save-delivery-dialog {
    > .ctrl {
        text-align: center;
    }
    > .tips {
        font-size: 85%;
        color: $color-text-minor;
        margin-bottom: $margin;
    }

    > .save-info {
        > .item {
            line-height: 1.2;
            padding: $padding 0;
            > .label {
                font-size: 85%;
                color: $color-text-minor;
            }
            > .value {
            }

            + .item {
                border-top: 1px solid $color-border;
            }
        }
    }
}
</style>
