<!-- Created by henian.xu on 2023/11/22. -->

<template>
    <div class="turntable">
        <div class="bg">
            <img src="./images/turntable-back.png">
        </div>
        <div class="inner">
            <div
                v-for="(item,index) in giftList"
                :key="index"
                class="item"
                :style="itemStyles"
            >
                <div class="inner">
                    <div class="gift">
                        <img :src="item.src">
                    </div>
                </div>
            </div>
            <div
                class="start-btn"
                @click="()=>{onStart()}"
            >
                <div
                    class="pointer"
                    :style="pointerStyles"
                    @transitionend="onPointerTransitionend"
                >
                    <img src="./images/pointer.png">
                </div>
                <img src="./images/start-btn.png">
            </div>
        </div>
        <div class="front">
            <img src="./images/turntable-front.png">
        </div>
    </div>
</template>

<script>
/*import gift01 from './images/gift01.png';
import gift02 from './images/gift02.png';
import gift03 from './images/gift03.png';
import gift04 from './images/gift04.png';
import gift05 from './images/gift05.png';
import gift06 from './images/gift06.png';
import gift07 from './images/gift07.png';
import gift08 from './images/gift08.png';
import gift09 from './images/gift09.png';*/

export default {
    name: 'Turntable',
    data() {
        return {
            /*giftList: [
                { src: gift01 },
                { src: gift02 },
                { src: gift03 },
                { src: gift04 },
                { src: gift05 },
                { src: gift06 },
                { src: gift07 },
                { src: gift08 },
                { src: gift09 },
            ],*/
            pointerStyles: '',
            playing: false,
            pointerIndex: null,
            resultGift: null,
        };
    },
    props: {
        dataset: {
            type: Array,
            default: () => [],
        },
        propKey: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        propKeyMap() {
            return {
                src: 'src',
                ...this.propKey,
            };
        },
        giftList() {
            const { propKeyMap } = this;
            return this.dataset.map(item => {
                return {
                    ...item,
                    src: item[propKeyMap.src],
                };
            });
        },
        giftListMap() {
            return this.giftList.reduce((pre, cur, index) => {
                pre[cur.id] = {
                    data: cur,
                    index,
                };
                return pre;
            }, {});
        },
        giftLength() {
            return this.giftList.length;
        },
        itemStyles() {
            const { giftLength } = this;
            return {
                '--gift-length': giftLength,
            };
        },
    },
    methods: {
        async emitStart() {
            return new Promise((resolve, reject) => {
                if (!this.$listeners.start) {
                    reject('未绑定 start 事件');
                    return;
                }
                this.$emit('start', { resolve, reject });
            });
        },
        async onStart() {
            if (this.playing) return;
            const { giftListMap } = this;
            this.resultGift = await this.emitStart();
            const gift = giftListMap[this.resultGift.rafflePrizeId];
            if (!gift) return;
            this.play(gift.index);
        },
        play(index) {
            if (this.playing) return;
            const { giftLength } = this;
            const minDeg = 360 / giftLength;
            const deg = index * minDeg + 360 * 8;
            this.pointerIndex = index;
            this.playing = true;
            this.pointerStyles = {
                transition: `transform 5s ease-in-out`,
                transform: `translateX(-50%) rotate(${deg}deg)`,
            };
        },
        onPointerTransitionend() {
            const { pointerIndex, giftLength } = this;
            const deg = pointerIndex * (360 / giftLength);
            this.pointerStyles = {
                transform: `translateX(-50%) rotate(${deg}deg)`,
            };
            this.playing = false;
            this.$emit('end', this.resultGift);
        },
    },
};
</script>

<style lang="scss">
$size: 6.38rem;
.turntable {
    position: relative;
    width: $size;
    height: $size;
    margin: 0 auto;

    > .bg,
    > .front {
        pointer-events: none;
    }
    > .bg,
    > .front,
    > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > .inner {
        border-radius: 100vw;

        > .item {
            position: absolute;
            left: 50%;
            height: 50%;
            padding-top: 1rem;
            transform-origin: 50% ($size / 2);
            transform: translateX(-50%);
            //background-color: rgba(#f00, 0.5);
            > .inner {
                > .gift {
                    width: 1rem;
                    height: 1rem;
                    transform-origin: 50% 50%;
                    //background-color: #0f0;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                width: 0.02rem;
                height: 100%;
                transform-origin: 50% 100%;
                transform: translateX(-50%);
                background-color: #320f00;
            }

            @for $i from 1 through 16 {
                &:nth-child(#{$i}) {
                    $degVal: (360deg / var(--gift-length));
                    transform: translateX(-50%) rotate(calc(#{$degVal} * #{$i - 1}));

                    > .inner {
                        > .gift {
                            transform: rotate(calc(#{-$degVal} * #{$i - 1}));
                        }
                    }

                    &:before {
                        transform: translateX(-50%) rotate(calc(#{-$degVal} / 2));
                    }
                }
            }
        }

        > .start-btn {
            $startBtnSize: 1.08rem;
            position: absolute;
            top: 50%;
            left: 50%;
            width: $startBtnSize;
            height: $startBtnSize;
            transform: translate(-50%, -50%);
            > img {
                position: relative;
                z-index: 100;
            }

            > .pointer {
                $pointerSize: 0.48rem;
                position: absolute;
                //z-index: 100;
                bottom: 50%;
                left: 50%;
                width: $pointerSize;
                height: ($startBtnSize / 2) + ($pointerSize - 0.08);
                transform-origin: 50% 100%;
                transform: translateX(-50%);
                //transform: translateX(-50%) rotate((360deg / 9));
            }
        }
    }
}
</style>
