import Vue from 'vue';
const state = Vue.observable({
    docHidden: true,
});

const doc = document;

doc.addEventListener('visibilitychange', (/*event*/) => {
    state.docHidden = doc.hidden;
});

export const turntableMixin = {
    computed: {
        docHidden() {
            return state.docHidden;
        },
    },
    watch: {
        docHidden(val, oldVal) {
            if (val === false && oldVal === true) {
                if (this.onConcern) this.onConcern();
            }
        },
    },
};
