<!-- Created by henian.xu on 2023/11/29. -->

<template>
    <Page>
        <Container ref="container">
            <List>
                <GiftItem
                    v-for="(item,index) in listData"
                    :key="item.id"
                    :dataset="item"
                    @change="(e)=>{onGiftItemChange(e,index)}"
                />
            </List>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </Container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';
import GiftItem from 'pages/lottery/turntable/_giftItem.vue';

export default {
    name: 'GiftListPage',
    components: { GiftItem },
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            listData: [],
        };
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Pm.Raffle.userRaffleDetailList({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.listData = this.listData.concat(res.data);
                return Promise.resolve(json);
            });
        },
        onGiftItemChange(data, index) {
            this.listData.splice(index, 1, data);
        },
    },
};
</script>

<style lang="scss">
</style>
